import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import classNames from 'classnames';

export default function Communications({ list }) {
  return (
    <div className={classNames('w-full')}>
      <List className="p-0 space-y-2">
        {list.map(
          ({ text, title, button, severity = 'info' }, i) => {
            const severityTrimmed = severity
              ? severity?.trim()
              : 'info';

            return (
              <ListItem
                key={i}
                className={classNames(
                  'max-w-sm md:max-w-none rounded-sm ',
                  {
                    'bg-primary text-white ':
                      severityTrimmed === 'info',

                    'bg-amber-300 text-amber-800':
                      severityTrimmed === 'warning',
                    'bg-red-600  text-white':
                      severityTrimmed === 'error',
                    'bg-green-500  text-white':
                      severityTrimmed === 'success',
                  },
                )}
              >
                <div className="mb-1 space-y-1" key={i}>
                  <div className="flex">
                    <div className="h-full">
                      <InfoOutlinedIcon
                        className={classNames(
                          severityTrimmed === 'warning'
                            ? 'fill-amber-800'
                            : 'fill-white',
                        )}
                      />
                    </div>

                    <div className="flex flex-col ml-4">
                      {title && (
                        <span
                          className={classNames(
                            'my-0.5',
                            'font-title',
                            'text-sm',

                            'font-semibold uppercase',
                          )}
                        >
                          {title}
                        </span>
                      )}

                      {text && (
                        <p className="w-full max-w-md text-sm leading-relaxed md:text-base">
                          {text}
                        </p>
                      )}

                      {button && (
                        <Link href={button?.hfref} passHref>
                          <a className=" pb-4 mt-4">
                            <Button
                              variant="outlined"
                              size="small"
                              className={classNames(
                                severityTrimmed === 'warning'
                                  ? 'text-white bg-amber-800'
                                  : 'text-white border-white',
                              )}
                            >
                              {button?.label}
                            </Button>
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </ListItem>
            );
          },
        )}
      </List>
    </div>
  );
}
