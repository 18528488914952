import * as React from 'react';
import Button from '@mui/material/Button';
import { Artist } from '@components';
import List from '@mui/material/List';
import sortBy from 'lodash/sortBy';
import { useRouter } from 'next/router';
import classNames from 'classnames';

export default function Artists({ list }) {
  const { push, locale } = useRouter();
  const onGoToPageArtists = () => push('/artists');
  const sortedArtistsList = sortBy(list, 'fullname');

  return (
    <>
      <List sx={{ width: '100%' }} component="nav">
        {sortedArtistsList?.map((artist, i) => {
          return <Artist {...artist} key={i} />;
        })}
      </List>

      <Button
        size="small"
        variant="contained"
        onClick={onGoToPageArtists}
        className={classNames(
          'uppercase bg-primary text-white',
          'flex items-center justify-center',
          'mx-auto w-full md:max-w-xs',
        )}
      >
        {locale === 'it' ? 'Archivio' : 'Archive'}
      </Button>
    </>
  );
}
